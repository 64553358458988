// app下载页路由
export default [
  // ios和安卓
  {
    path: '/downloadPage',
    name: 'index',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/downloadPage/index.vue')
  },
  // 只下载ios
  {
    path: '/downloadIos',
    name: 'ios',
    component: () =>
      import(/* webpackChunkName: "ios" */ '@/views/downloadPage/ios.vue')
  },
  // 只下载安卓
  {
    path: '/downloadAndroid',
    name: 'android',
    component: () =>
      import(
        /* webpackChunkName: "android" */ '@/views/downloadPage/android.vue'
      )
  }
]
