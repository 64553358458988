/**
 * 微信公众号充值接口
 */

// 请求地址
import { config } from '@/config'

// api地址管理
import request from '@/http/request'

/**
 * params：接口参数信息
 * options：接口配置信息，(loading: true/false; 是否显示加载信息。error: true/false; 是否显示接口报错信息）
 */
export default {
  // 获取验证码
  loginGetCodeApi(params, options = { tokenRequired: false }) {
    return request.get(`${config.javaUrl}/mobile`, params, options)
  },
  // 登录
  loginApi(params, options = { isNeedLogin: true }) {
    return request.post(`${config.javaUrl}/auth/oauth2/token`, params, options)
  },
  // 退出登录
  loginOutApi(params, options = { isNeedLogin: true }) {
    return request.post(
      `${config.javaUrl}/user/userInfo/logout`,
      params,
      options
    )
  },
  // 公众号提现登录成功后请求接口
  getWxUseraccountsInfo(
    params,
    options = { encrypt: 'h5_encryption', isNeedLogin: true }
  ) {
    return request.post(
      `${config.javaUrl}/user/app/useraccounts/info`,
      params,
      options
    )
  },
  // 发起提现
  wxOrderWithdrawalSave(
    params,
    options = { encrypt: 'h5_encryption', isNeedLogin: true }
  ) {
    return request.post(
      `${config.javaUrl}/finance/admin/orderWithdrawal/save`,
      params,
      options
    )
  },
  // 提现列表
  getWxOrderWithdrawal(params, options = { isNeedLogin: true }) {
    return request.get(
      `${config.javaUrl}/finance/h5/recharge/getOrderListByUserId`,
      params,
      options
    )
  },
  // 获取用户自己信息
  getWxUserInfo(
    params,
    options = { encrypt: 'h5_encryption', isNeedLogin: true }
  ) {
    return request.post(`${config.javaUrl}/user/userInfo/info`, params, options)
  },

  // 根据number搜索用户
  getUserInfoByNumber(params, options = { isNeedLogin: true }) {
    return request.get(
      `${config.javaUrl}/finance/h5/recharge/getUserInfoByNumber`,
      params,
      options
    )
  },
  // 充值选项列表
  getWxDiamondExchangeList(
    params,
    options = { encrypt: `h5_encryption`, isNeedLogin: true }
  ) {
    return request.get(
      `${config.javaUrl}/finance/h5/recharge/diamondExchangeList`,
      params,
      options
    )
  },
  // 获取公众号用户openId
  getOpenIdApi(
    params,
    options = { encrypt: `h5_encryption`, isNeedLogin: true }
  ) {
    return request.post(
      `${config.javaUrl}/finance/h5/recharge/getOpenId`,
      params,
      options
    )
  },
  // 充值
  wxRechargeBuyDiamond(
    params,
    options = { encrypt: `h5_encryption`, isNeedLogin: true }
  ) {
    return request.post(
      `${config.javaUrl}/finance/h5/recharge/buyDiamond`,
      params,
      options
    )
  },
  // 绑定银行卡
  wxAddUserBankCard(params, options = { isNeedLogin: true }) {
    return request.post(
      `${config.javaUrl}/user/app/userBankCard/addUserBankCard`,
      params,
      options
    )
  },
  // 获取银行卡列表
  getUserBankCardList(params, options = { isNeedLogin: true }) {
    return request.post(
      `${config.javaUrl}/user/app/userBankCard/getUserBankCard`,
      params,
      options
    )
  },
  // 获取银行卡列表
  delUserBankCard(params, options = { isNeedLogin: true }) {
    return request.post(
      `${config.javaUrl}/user/app/userBankCard/delUserBankCard`,
      params,
      options
    )
  }
}
