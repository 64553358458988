import CryptoJS from 'crypto-js'
export default {
  encrypt(data) {
    var AesKey = '6T5tAURspVu0ScTX' //加密时用的key,跟php一样
    var message = JSON.stringify(data) //加密后的字符窜
    var key = CryptoJS.enc.Utf8.parse(AesKey)
    var encryptedData = CryptoJS.AES.encrypt(message, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
    return encryptedData.toString()
  },
  decrypt(data) {
    var key = CryptoJS.enc.Utf8.parse('6T5tAURspVu0ScTX')
    var decrypted = CryptoJS.AES.decrypt(data, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
    return JSON.parse(CryptoJS.enc.Utf8.stringify(decrypted))
  }
}
