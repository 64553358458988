// 榜单类型
export const RANK_TYPE = {
  RANK_CP: {
    desc: 'cp榜',
    value: 0
  },
  RANK_WEEK_STAR: {
    desc: '周星',
    value: 1
  },
  RANK_DAY: {
    desc: '日榜',
    value: 2
  },
  RANK_WEALTH: {
    desc: '财富',
    value: 3
  },
  RANK_NOBLE: {
    desc: '贵族',
    value: 4
  },
  RANK_ROOM: {
    desc: '房间',
    value: 5
  },
  RANK_NEW_PEOPLE: {
    desc: '新人',
    value: 6
  },
  RANK_PK: {
    desc: 'PK',
    value: 7
  }
}
