/**
 * 金桶聚宝接口
 */

// 请求地址
import { config } from '@/config'

// api地址管理
import request from '@/http/request'

/**
 * params：接口参数信息
 * options：接口配置信息，(loading: true/false; 是否显示加载信息。error: true/false; 是否显示接口报错信息）
 */
export default {
  // 页面初始化数据
  getGoldenBucketInitData(params, options) {
    return request.get(
      `${config.javaUrl}/luckyDraw/draw/getHistory`,
      params,
      options
    )
  },
  // 抽奖接口
  goldenBucketDrawPasture(params, options = { loading: false }) {
    return request.post(
      `${config.javaUrl}/luckyDraw/draw/drawPasture`,
      params,
      options
    )
  },
  // 我的记录 出光记录
  getGoldenBucketHistory(params, options) {
    return request.get(
      `${config.javaUrl}/luckyDraw/draw/getHistoryByUserId`,
      params,
      options
    )
  }
}
