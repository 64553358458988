// 微信公众号充值路由
export default [
  // 登录页面
  {
    path: '/wxLogin',
    name: 'wxLogin',
    component: () =>
      import(/* webpackChunkName: "wxLogin" */ '@/views/wxWallet/login.vue')
  },
  // 充值首页
  {
    path: '/wxRechargeIndex',
    name: 'wxRechargeIndex',
    component: () =>
      import(
        /* webpackChunkName: "wxRechargeIndex" */ '@/views/wxWallet/authorize.vue'
      )
  },
  // 充值页面
  {
    path: '/wxRecharge',
    name: 'wxRecharge',
    component: () =>
      import(
        /* webpackChunkName: "wxRecharge" */ '@/views/wxWallet/recharge.vue'
      )
  }
  // 支付宝充值页面
  // {
  //   path: '/wxAliPay',
  //   name: 'wxAliPay',
  //   component: () =>
  //     import(/* webpackChunkName: "wxAliPay" */ '@/views/wxWallet/aliPay.vue')
  // }
]
