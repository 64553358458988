// 平台榜单路由
export default [
  {
    path: '/rankList',
    name: 'rankList',
    component: () =>
      import(
        /* webpackChunkName: "rankList" */ '@/views/platformList/rankList.vue'
      )
  },
  {
    path: '/cpRank',
    name: 'cpRank',
    component: () =>
      import(/* webpackChunkName: "cpRank" */ '@/views/platformList/cpRank.vue')
  }
]
