import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/rem'

// 按需引入vant
import {
  Field,
  Icon,
  Toast,
  Button,
  Search,
  Checkbox,
  Popup,
  Cell,
  RadioGroup,
  Radio,
  Swipe,
  SwipeItem,
  ActionSheet,
  List,
  Dialog,
  Area
} from 'vant'
Vue.use(Field)
  .use(Icon)
  .use(Toast)
  .use(Button)
  .use(Search)
  .use(Checkbox)
  .use(Popup)
  .use(Cell)
  .use(RadioGroup)
  .use(Radio)
  .use(Swipe)
  .use(SwipeItem)
  .use(ActionSheet)
  .use(List)
  .use(Dialog)
  .use(Area)
import 'vant/lib/icon/local.css'

// 将各模块api挂载在vue实例上
import api from './http'
Vue.prototype.$api = api

// jsBridge 和app的交互
import Bridge from './utils/JSbridge'
Vue.prototype.$bridge = Bridge

// 全局自定义指令
import myDirection from './directive/index'
Vue.use(myDirection)

// 引入常量
import './constants/index'

// 标题
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

// 抽奖
import VueLuckyCanvas from '@lucky-canvas/vue'
Vue.use(VueLuckyCanvas)

// 开发测试环境显示vconsole
import VConsole from 'vconsole'
if (process.env.VUE_APP_DEBUG_TOOL === 'vconsole') {
  new VConsole()
}

// 全局组件自动注册
import '@/components/global/autoRegisterGlobalComponents'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
