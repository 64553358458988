/**
 * 祈愿莲花接口
 */

// 请求地址
import { config } from '@/config'

// api地址管理
import request from '@/http/request'

/**
 * params：接口参数信息
 * options：接口配置信息，(loading: true/false; 是否显示加载信息。error: true/false; 是否显示接口报错信息）
 */
export default {
  // 页面初始化数据
  getlotusFlowerInitData(params, options) {
    return request.get(
      `${config.javaUrl}/expensive/draw/getHistory`,
      params,
      options
    )
  },
  // 抽奖接口
  lotusFlowerDrawPasture(params, options = { loading: false }) {
    return request.post(
      `${config.javaUrl}/expensive/draw/drawPasture`,
      params,
      options
    )
  },
  // 我的记录 出光记录
  getlotusFlowerHistory(params, options) {
    return request.get(
      `${config.javaUrl}/expensive/draw/getHistoryByUserId`,
      params,
      options
    )
  }
}
