/**
 * app协议接口
 */

// 请求地址
import { config } from '@/config'

// api地址管理
import request from '@/http/request'

/**
 * params：接口参数信息
 * options：接口配置信息，(loading: true/false; 是否显示加载信息。error: true/false; 是否显示接口报错信息）
 */
export default {
  // 获取app协议
  getArticle(params) {
    return request.post(
      `${config.javaUrl}/msg/app/article/getArticle/${params}`
    )
  }
}
