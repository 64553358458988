/**
 * 数字转换 万位下显示具体数据，十万位后显示单位为W，精确到小数点后一位
 * @param {Object} vNode Vue 编译生成的虚拟节点（当前绑定节点）
 * @param {DOM} el  指令所绑定的元素，可以用来直接操作 DOM。
 */

function numHandler(data) {
  if (data >= 0 && data < 10000) {
    data = Number(data)
  }
  if (data >= 10000) {
    data = Math.floor((Number(data) / 10000) * 10) / 10 + 'w'
  }
  return data
}

const numFilter = {
  bind(el, binding) {
    el.innerHTML = numHandler(Number(binding.value))
  },
  update(el, binding) {
    el.innerHTML = numHandler(Number(binding.value))
  }
}

export default numFilter
