// 祈愿莲花路由
export default [
  {
    path: '/lotusFlower',
    name: 'lotusFlower',
    component: () =>
      import(
        /* webpackChunkName: "lotusFlower" */ '@/views/lotusFlower/index.vue'
      )
  }
]
