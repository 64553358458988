/**
 * 生成基础axios对象，并对请求和响应做处理
 * 前后端约定接口返回解构规范
 * {
 *    code:0,
 *    data:"成功",
 *    message:""
 * }
 */

import axios from 'axios' // 引入axios
import { Toast } from 'vant'
import AES from '@/utils/crypt'
import router from '@/router/index'

const toLogin = () => {
  router.push({
    path: '/wxLogin',
    query: {
      redirect: router.currentRoute.fullPath
    }
  })
}

/**
 * 创建 axios 实例
 * baseURL：根据 .env 配置文件切换
 * timeout：1000 * 10ms
 * headers.post: 设置post请求的默认请求头
 */
const instance = axios.create({
  timeout: 1000 * 100,
  headers: {
    post: {
      'Content-Type': 'application/json'
    }
  }
})

/**
 * 请求拦截器
 * 每次请求把 sessionStorage 的 token 添加到请求头里
 */
instance.interceptors.request.use(
  (config) => {
    let { encrypt, tokenRequired, from } = config.options
    let regexLoginUrl = new RegExp('.*auth/oauth2/token.*'), // 登录接口
      regexCode = new RegExp('/')

    if (regexLoginUrl.test(config.url)) {
      // 登录接口：接口是post方法，但是参数是拼接在url上，因此需要特殊处理
      let paramsInput = JSON.stringify(config.data.params)
      let paramsOut = paramsInput
        .replace(regexCode, '')
        .substr(0, paramsInput.length - 1)
        .substr(1)
      const token = 'Basic aDU6aDU='
      config.headers.Authorization = token
      config.url = `${config.url}?${paramsOut}`
      return config
    }

    if (tokenRequired) {
      // 需要token的接口
      const token =
        sessionStorage.getItem('token') || localStorage.getItem('token')
      token && (config.headers['Authorization'] = token)
      if (encrypt === 'h5_encryption') {
        // h5_encryption 需要加密的接口。加密方法（旧），AesKey='6TUtAURapSu0qSTX'。充值、提现接口使用
        config.data = {
          data: AES.encrypt(config.data)
        }
        return config
      } else {
        if (from !== 'php') {
          // php接口不需要此请求头
          config.headers['h5Basic'] = 'aDU6aDU='
        }
        return config
      }
    } else {
      // 不需要token的接口：获取验证码
      return config
    }
  },
  (error) => Promise.error(error)
)

/**
 * 响应拦截器
 */
instance.interceptors.response.use(
  (response) => {
    const res = response.data
    // 这里根据 返回的状态码做特殊处理，比如未登录跳转登录页，错误显示可在service中控制，因为某些场景我们不想要展示错误
    // 未登录 失败提示
    let regexLoginOutUrl = new RegExp('.*user/userInfo/logout.*') // 退出登录接口
    if (!regexLoginOutUrl.test(response.config.url)) {
      // 这里根据 返回的状态码做特殊处理，比如未登录跳转登录页，错误显示可在service中控制，因为某些场景我们不想要展示错误
      if (res.code && res.code === 401) {
        if (response.config.options.isNeedLogin) {
          // 需要跳转登录页的接口，去登录
          Toast.clear()
          toLogin()
        }
      }
      if (res.code && res.code === 1) {
        Toast(res.msg)
      }
      if (typeof res.data == 'string') {
        res.data = AES.decrypt(res.data)
      }
    }
    return res
  },
  (error) => {
    console.log('----error-----', error)
    let regexLoginUrl = new RegExp('.*auth/oauth2/token.*') // 登录接口
    if (error.response) {
      if (error.response.status === 401) {
        if (regexLoginUrl.test(error.response.config.url)) {
          Toast.fail({
            message: error.response.data.msg,
            duration: 1500,
            forbidClick: true
          })
        } else {
          if (error.response.config.options.isNeedLogin) {
            // 需要跳转登录页的接口，去登录
            Toast.clear()
            toLogin()
          } else {
            Toast(error.response.data.msg)
          }
        }
      } else {
        //接口报错失败后 提示错误 并关闭提示
        if (error.response.data.msg) Toast(error.response.data.msg)
        if (error.response.data.message) Toast(error.response.data.message)
      }
    } else {
      Toast.fail({
        message: '出错了~',
        duration: 1500,
        forbidClick: true
      })
    }

    return Promise.reject(error)
  }
)

export default instance
