const projectDirectives = []
const require_module = require.context('@/directive/modules', true, /.js$/)
require_module.keys().forEach((file_name) => {
  projectDirectives[file_name.slice(2, -3)] = require_module(file_name).default
})

const directives = { ...projectDirectives }

export default {
  // Vue.use() 其实就是调用 install(Vue) 方法
  install(Vue) {
    Object.keys(directives).forEach((key) => {
      Vue.directive(key, directives[key])
    })
  }
}
