/**
 *
 * @param {Object} vNode Vue 编译生成的虚拟节点（当前绑定节点）
 * @param {DOM} el  指令所绑定的元素，可以用来直接操作 DOM。
 */

function wordLimitHandler(data, length) {
  const wordLength = data && data.length
  if (wordLength > length) {
    return data.substring(0, length) + '...'
  } else {
    return data || ''
  }
}

const wordLimit = {
  bind(el, binding) {
    el.innerHTML = wordLimitHandler(binding.value.data, binding.value.length)
  },
  update(el, binding) {
    el.innerHTML = wordLimitHandler(binding.value.data, binding.value.length)
  }
}

export default wordLimit
