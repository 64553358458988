/**
 * api地址管理
 */
const projectApis = {}
const require_module = require.context('@/api/', true, /.js$/)
require_module.keys().forEach((file_name) => {
  Object.assign(projectApis, require_module(file_name).default)
})

const api = { ...projectApis }
export default api
