/**
 * 通过 promise 对 axios 做二次封装，针对用户端参数，做灵活配置
 */

import { Toast } from 'vant'
import instance from './http'

function startLoading() {
  // 开始加载
  Toast.loading({
    message: '加载中...',
    duration: 15000,
    forbidClick: true
  })
}

/**
 * 核心函数，可通过它处理一切请求数据，并做横向扩展
 * @param {string} url 请求地址
 * @param {string} method 请求方法 get/post
 * @param {object} params 请求参数
 * @param {object} options 请求配置，针对当前本次请求；
    * @param {boolean} loading 本次是否显示loading。默认true，即显示loading
    * @param {boolean} error 本次是否显示错误。默认true，即显示提示错误
    * @param {boolean} errAction 本次提示错误后是否有其他操作。默认false，即只提示错误，不进行其他操作
    * @param {boolean} tokenRequired token是否是必需的。默认true，即token必需传
    * @param {string} encrypt
    * 加密方法，默认不加密。可选值：
    * h5_encryption（java加密方法，旧。AesKey = '6TUtAURapSu0qSTX'）充值、提现接口使用
    * @param {boolean} isNeedLogin 是否需要跳转到登录页

 */
function request(url, method, params, options) {
  let defaultOptions = {
    loading: true,
    error: true,
    errAction: false,
    tokenRequired: true,
    encrypt: '',
    isNeedLogin: false
  }
  options = { ...defaultOptions, ...options }
  // 请求前 loading
  if (options.loading) startLoading()
  return new Promise((resolve, reject) => {
    let data = {}
    // get请求使用params字段
    if (method === 'get') data = { params }
    // post请求使用data字段
    if (method === 'post') data = { data: params }

    instance({ url, method, ...data, options })
      .then((res) => {
        // 对登录接口的特殊处理
        let regexUrl = new RegExp('.*auth/oauth2/token.*')

        if (regexUrl.test(url) || options.errAction) {
          resolve(res)
        }

        if (res && res.code === 0) {
          Toast.clear()
          resolve(res)
        } else {
          // 通过配置可关闭错误提示
          if (options.error) {
            Toast.fail({
              message: res.msg || res.message,
              duration: 1500,
              forbidClick: true
            })
          }
          reject(res)
        }
      })
      .catch((error) => {
        console.log(error)
        reject(error)
      })
  })
}

// 封装GET请求
function get(url, params, options) {
  return request(url, 'get', params, options)
}

// 封装POST请求
function post(url, params, options) {
  return request(url, 'post', params, options)
}

export default { get, post }
