// app协议路由
export default [
  {
    path: '/protocolPage',
    name: 'agreement',
    component: () =>
      import(/* webpackChunkName: "agreement" */ '@/views/agreement/index.vue')
  },
  {
    path: '/protocolImg',
    name: 'agreementImg',
    component: () =>
      import(
        /* webpackChunkName: "agreementImg" */ '@/views/agreement/indexImg.vue'
      )
  }
]
