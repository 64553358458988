// 金桶聚宝路由
export default [
  {
    path: '/goldenBucket',
    name: 'goldenBucket',
    component: () =>
      import(
        /* webpackChunkName: "goldenBucket" */ '@/views/goldenBucket/index.vue'
      )
  }
]
