import Vue from 'vue'
import VueRouter from 'vue-router'
const _ = require('lodash')

Vue.use(VueRouter)

const routes = []
const require_module = require.context('./modules', true, /.js$/)
require_module.keys().forEach((file_name) => {
  routes.push(require_module(file_name).default)
})

const router = new VueRouter({
  routes: _.flatMap(routes)
})

export default router
