/**
 * 全局组件自动注册
 * 参考： https://juejin.cn/post/6844903893244051463
 * 全局组件各个组件按文件夹区分，文件夹名称与组件名无关联，但建议与组件名保持一致
 * 文件夹内至少保留一个文件名为 index 的组件入口，例如 index.vue
 * 普通组件必须设置 name 并保证其唯一，自动注册会将组件的 name 设为组件名
 * 如果组件是通过 js 进行调用，则确保组件入口文件为 index.js
 *
 * require.context()：webpack的api，通过执行require.context函数获取一个特定的上下文，主要用来实现自动化导入模块，在前端工程中，如果遇到从* 一个文件夹引入很多模块的情况，可以使用这个api，它会遍历文件夹中的指定文件，然后自动导入，使得不需要每次显式的调用import导入模块。
 * 接收三个参数：
 * 1、directory{String} 读取文件的路径
 * 2、useSubdirectories{Boolean} 是否遍历文件的子目录
 * 3、regExp{RegExp} 匹配文件的正则
 */

/**
 * 注意：
 * 1）先读取 ./ 文件夹及其子文件夹的 Index.vue 文件，然后循环依次读取文件中的default模块，并使用组件的 name 作为组件名进行组件注* 册。
 * 因为使用的是组件的 name 进行的注册组件，所以组件必须设置 name 值且它是唯一的。
 * 2) 组件的另外一种调用方式，通过 js 调用，这里没用到，暂时不说。
 */

import Vue from 'vue'

const componentsContext = require.context('.', true, /Base[A-Z]\w+\.(vue|js)$/)
componentsContext.keys().forEach((file_name) => {
  // 获取文件中的 default 模块
  const componentConfig = componentsContext(file_name).default
  if (/.vue$/.test(file_name)) {
    // 使用组件的组件名进行注册
    Vue.component(componentConfig.name, componentConfig)
  } else {
    Vue.use(componentConfig)
  }
})
